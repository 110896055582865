import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";
import Helmet from "react-helmet";


export default ({props, data}) => (
  <Layout>
    <Seo
      title={`Sobre ${data.site.siteMetadata.title}`}
      description={data.site.siteMetadata.description} />
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <div className="mw9 center flex flex-wrap pv5-l w-100">
      <div className="mw7 w-100 pa2">

        <h1 className="display fw1 db lh-copy"> 404 // Página no encontrada</h1>

        <p className="mw7 w-100 lh-copy serif pa2 flex flex-column justify-center f4" >        No se ha podido encontrar lo que buscabas.</p>
        <Link to="/" className="dib bg-dark-gray b near-white hover-bg-mid-gray pv3 ph4 ttu tracked sans-serif no-underline mv2">Ir a inicio</Link>
      </div>

    </div>
  </Layout>


)


export const dataQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        description
      }
    }
  }`
